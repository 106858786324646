export default [{
    "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
    "description": "A simple bar chart with embedded data.",
    "data": {
      "values": [
        {"a": "A","b": 28}, {"a": "B","b": 55}, {"a": "C","b": 43},
        {"a": "D","b": 91}, {"a": "E","b": 81}, {"a": "F","b": 53},
        {"a": "G","b": 19}, {"a": "H","b": 87}, {"a": "I","b": 52}
      ]
    },
    "mark": "bar",
    "encoding": {
      "x": {"field": "a", "type": "ordinal"},
      "y": {"field": "b", "type": "quantitative"}
    }
  },
  {
    "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
    "description": "A bar chart showing the US population distribution of age groups in 2000.",
    "data": { "url": "data/population.json"},
    "transform": [{"filter": "datum.year == 2000"}],
    "mark": "bar",
    "encoding": {
      "y": {
        "field": "age", "type": "ordinal",
        "scale": {"rangeStep": 17}
      },
      "x": {
        "aggregate": "sum", "field": "people", "type": "quantitative",
        "axis": {"title": "population"}
      }
    }
  },
    {
      "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
      "data": {"url": "data/movies.json"},
      "mark": "bar",
      "encoding": {
        "x": {
          "bin": true,
          "field": "IMDB_Rating",
          "type": "quantitative"
        },
        "y": {
          "aggregate": "count",
          "type": "quantitative"
        }
      }
    },
    {
      "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
      "data": { "url": "data/population.json"},
      "transform": [
        {"filter": "datum.year == 2000"},
        {"calculate": "datum.sex == 2 ? 'Female' : 'Male'", "as": "gender"}
      ],
      "mark": "bar",
      "encoding": {
        "column": {
          "field": "age", "type": "ordinal"
        },
        "y": {
          "aggregate": "sum", "field": "people", "type": "quantitative",
          "axis": {"title": "population", "grid": false}
        },
        "x": {
          "field": "gender", "type": "nominal",
          "scale": {"rangeStep": 12},
          "axis": {"title": ""}
        },
        "color": {
          "field": "gender", "type": "nominal",
          "scale": {"range": ["#EA98D2", "#659CCA"]}
        }
      },
      "config": {
        "view": {"stroke": "transparent"},
        "axis": {"domainWidth": 1}
      }
    },
    {
      "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
      "data": {"url": "data/seattle-weather.csv"},
      "mark": "bar",
      "encoding": {
        "x": {
          "timeUnit": "month",
          "field": "date",
          "type": "ordinal",
          "axis": {"title": "Month of the year"}
        },
        "y": {
          "aggregate": "count",
          "type": "quantitative"
        },
        "color": {
          "field": "weather",
          "type": "nominal",
          "scale": {
            "domain": ["sun","fog","drizzle","rain","snow"],
            "range": ["#e7ba52","#c7c7c7","#aec7e8","#1f77b4","#9467bd"]
          },
          "legend": {"title": "Weather type"}
        }
      }
    },    
    {
      "$schema": "https://vega.github.io/schema/vega-lite/v3.json",
      "data": {"url": "data/barley.json"},
      "mark": "bar",
      "encoding": {
        "x": {"aggregate": "sum", "field": "yield", "type": "quantitative"},
        "y": {"field": "variety", "type": "nominal"},
        "color": {"field": "site", "type": "nominal"}
      }
    }
  ]