import React, { Component } from 'react';
import './App.css';
import Chart from "./Chart"
import { nodes, links} from "./vegalitesamples/sampleOutputs"
class App extends Component {

  render() {
    return (
      <Chart nodes={JSON.parse(nodes)} links={JSON.parse(links)}/>
    );
  }
}

export default App;
