import React, {
    Component
} from 'react';
import * as d3 from "d3";
import * as graphscape from "graphscape";
import PropTypes from "prop-types";
import chartSamples from "./vegalitesamples/charts"

const getNodes = () => {
    const nodes = chartSamples.map((chart, id) => ({
        id,
        vegaliteSpec: chart
    }));
    console.log(nodes, JSON.stringify(nodes));
    return nodes;
}

const getLinkValue = cost => 10 - Math.exp(0.2 * cost)

const getLinks = (nodes, progressCallback) => {
    const links = [];
    nodes.forEach((outer, i) => {
        nodes.forEach((inner, k) => {
            if (outer.id !== inner.id && !links.includes(l => l.source === inner.id && l.target === outer.id)) {
                const transition = graphscape.transition(outer.vegaliteSpec, inner.vegaliteSpec);
                let {
                    cost
                } = transition;
                links.push({
                    source: outer.id,
                    target: inner.id,
                    value: getLinkValue(cost),
                    cost,
                    transition
                })
            }
            const progress = {
                i,
                k,
                percentage: Math.floor(100 * (i / nodes.length + k / (nodes.length * nodes.length)))
            }
            console.log(progress);
            if (progressCallback) progressCallback(progress)
        })
    })
    console.log(links, JSON.stringify(links));
    return links;
}

const getLinkDistance = link => ((link.cost || link.cost === 0) ? link.cost : getLinkDistanceWithTranstion(link)) * 20;

const getLinkDistanceWithTranstion = link => {
    const {
        source,
        target
    } = link;
    const transition = graphscape.transition(source.vegaliteSpec, target.vegaliteSpec);
    link.transition = transition;
    link.cost = transition.cost;
    return transition.cost;
};

class Chart extends Component {

    state = {
        links: [],
        nodes: [],
        progress: {},
        ignoreImported: false
    }

    componentDidMount() {
        this.setLinksAndNodes();
    }

    setLinksAndNodes = () => {
        const { ignoreImported } = this.state;
        const { links: importedLinks, nodes:importedNodes } = this.props;
        console.log({ignoreImported, importedLinks, importedNodes});
        if (!ignoreImported && importedLinks && importedNodes){
            this.setState({
                links: importedLinks,
                nodes: importedNodes
            }, () => this.drawForceField())
            return;
        }
        const nodes = getNodes();
        /**
         * TODO
         * calculate links in a background thread
         * display the progress in the ui
         */
        const links = getLinks(nodes);
        this.setState({
            links,
            nodes
        }, () => this.drawForceField())
    }

    drawForceField = () => {
        const {
            width,
            height
        } = this.props;
        const {
            nodes,
            links
        } = this.state;
        const nodeColor = "#222222";
        const linkColor = "#222222";

        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.id).distance(getLinkDistance))
            .force("charge", d3.forceManyBody())
            .force("center", d3.forceCenter(width / 2, height / 2));

        const svg = d3.select(this.getChartName("#"))
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .style("margin-left", 100);

        const link = svg.append("g")
            .attr("stroke", linkColor)
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .enter().append("line")
            .attr("stroke-width", d => Math.sqrt(d.value));

        const node = svg.append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("circle")
            .data(nodes)
            .enter().append("circle")
            .attr("r", 6)
            .attr("fill", nodeColor);

        node.append("title").text(d => `${d.vegaliteSpec.description || ""}\n\n${JSON.stringify(d.vegaliteSpec)}`);

        simulation.on("tick", () => {
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node
                .attr("cx", d => d.x)
                .attr("cy", d => d.y);
        });
    }

    getChartName = (prefix = "") => {
        const {
            id
        } = this.props;
        return `${prefix}chart-${id}`;
    }

    toggleImported = () => {
        this.setState({
            ignoreImported: !this.state.ignoreImported
        })
    }

    render() {
        const { ignoreImported } = this.state;
        return ( <div>
            <div id={this.getChartName()}>

            </div>
            <button onClick = {this.setLinksAndNodes}>Rerender</button>
            <button onClick = {this.toggleImported}>{ `Toggle whether precalculated, imported distances are used (current: ${ignoreImported ? "not imported" : "imported"}` }</button></div>)
        }
    }

    export default Chart;

    Chart.propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        links: PropTypes.array,
        nodes: PropTypes.array,
    }

    Chart.defaultProps = {
        width: window.innerWidth-100,
        height: window.innerHeight-100
    }